body,
button,
input,
select,
textarea {
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Archivo', sans-serif;
}

#root {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.text-center {
  text-align: center;
}

.consent-btn {
  margin-top: 25px;
}

.consent-checkbox input {
  margin-right: 10px;
}

.radio input {
  margin-right: 10px;
}

.control-stacked {
  display: flex;
  flex-direction: column;
}

.control-stacked .radio + .radio {
  margin: 0;
  margin-top: 0.5em;
}

.footer {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
}

.checkbox {
  width: 30px;
  min-width: 30px;
  height: 30px;
  background-color: #dddbe0;
  border-radius: 5px;
  transition: 0.2s ease all;
  margin-right: 15px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.checkbox:hover {
  background-color: #9e9e9e;
}

.checkbox.is-checked {
  background-color: #00d1b2;
}

.checkbox.is-checked:hover {
  background-color: #029c85;
}

@media screen and (max-width: 650px) {
  /* Remove Hover styles */
  .checkbox:hover {
    background-color: #dddbe0;
  }
  .checkbox.is-checked:hover {
    background-color: #00d1b2;
  }
}

/* ======================= */
/* Need Selection Styles */
/* ======================= */
.needs-selection-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.needs-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.need-icon {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8em;
  width: 100px;
  height: 125px;
  padding: 10px;
  margin: 0 5px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s all ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.need-icon:hover {
  background-color: #e5e5e58a;
}
.need-icon.is-selected {
  background-color: #dddbe0;
}

@media only screen and (max-width: 650px) {
  .need-icon {
    width: 5.5em;
    height: 100px;
  }
  .need-icon:hover {
    background-color: transparent;
  }
  .need-icon.is-selected {
    background-color: #dddbe0;
  }
}

/* ================== */
/* Code Page Styles   */
/* ================== */

.code-page {
  height: 100vh;
  padding: 15px;
}

.code-container {
  max-width: 70%;
}

.code {
  margin: 50px 0;
}

.code-description {
  color: rgba(0, 0, 0, 0.5);
}

.code-text {
  font-size: 4rem;
}

/* ================== */
/* Skeleton Styles    */
/* ================== */

.skeleton {
  background-color: #e7e7e7;
  border-radius: 25px;
  animation: skel-loading 2s infinite;
}

.section-skel {
  width: 98%;
  height: 200px;
  border-radius: 0;
}

.txt-skel {
  height: 25px;
  width: 80%;
  margin: 10px;
}

.q-skel {
  height: 25px;
  width: 50%;
  margin: 15px;
}

.header-skel {
  height: 36px;
  width: 90%;
  margin: 10px;
}

.graph-skel {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

.checkbox-skel {
  border-radius: 5px;
  height: 30px;
  width: 30px;
  margin: 15px;
}

.global-loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes skel-loading {
  0% {
    background-color: #e7e7e7;
  }

  50% {
    background-color: #a5a5a5;
  }

  100% {
    background-color: #e7e7e7;
  }
}

/* ================== */
/* Button Loading     */
/* ================== */

.ring {
  width: 1em;
  height: 1em;
  position: relative;
  color: inherit;
  display: inline-block;
}
.ring:after {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: ' ';
  display: inline-block;
  background: center center no-repeat;
  background-size: cover;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.15em;
  -webkit-background-clip: padding-box;
  border-color: currentColor currentColor currentColor transparent;
  box-sizing: border-box;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spin {
  -webkit-animation: spin 1s infinite;
  animation: spin 1s infinite;
}

/* ================== */
/*Rating Slider Styles*/
/* ================== */

.rating-slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider,
.rating-description {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider span {
  margin: 0 10px;
}

.slider {
  justify-content: space-between;
  width: 150px;
}

.slider.immutable {
  width: 125px;
}

.slider-dot {
  background-color: #c4c4c4;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.rating-description {
  height: 25px;
  width: 125px;
  border-radius: 25px;
  font-size: 13px;
  margin: 10px 0 0 0;
  color: white;
}

.rating-description.immutable {
  margin: 10px 0 0 0;
}

.slider-dot.active {
  width: 20px;
  height: 20px;
  background-color: #0ca7d3;
}

/* ================== */
/*Global Report Styles*/
/* ================== */

.star-modal {
  background-color: white;
  border-radius: 10px;
  padding: 50px;
}

.report-legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.legend-title {
  font-size: 1.2em;
  color: #3636369a;
}
.legend-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.legend-item {
  display: flex;
  align-items: center;
  margin: 5px 20px 5px 0;
}
.legend-icon {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 5px;
}
.legend-icon--green {
  background-color: #23d160;
}
.legend-icon--red {
  background-color: #ff3860;
}
.legend-icon--alt-green {
  background-color: #2ebc2e;
}
.legend-icon--blue {
  background-color: #1e90ff;
}
.legend-icon--purple {
  background-color: #e95ae9;
}
.legend-icon--orange {
  background-color: #ffa500;
}

.two-level-pie {
  height: 375px;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.two-level-pie .title {
  margin-bottom: -50px;
  padding-left: 50px;
}
.challenge-count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3636369a;
  padding-left: 50px;
}
.challenge-count div {
  font-size: 36px;
  color: #363636;
}

/* ================== */
/* Summary Code Styles*/
/* ================== */

.summary-code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 6%;
}

/* ================== */
/* Font Toggle Styles */
/* ================== */

.font-size-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.regular-size,
.large-size {
  margin: 0 5px;
  height: 40px;
  width: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dddbe0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  transition: 0.3s ease all;
}

.regular-size:hover,
.large-size:hover {
  background-color: #b8b8b8;
}

.regular-size.active,
.large-size.active {
  color: white;
  background-color: #0ca7d3;
}

.regular-size {
  font-size: 16px;
}
.large-size {
  font-size: 24px;
}

@media only screen and (max-width: 650px) {
  .font-size-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .regular-size {
    font-size: 16px;
  }

  .large-size {
    font-size: 24px;
  }
}

/* ================== */
/* Landing Page Styles*/
/* ================== */

.landing-page {
  padding-top: 70px;
  height: 100vh;
}

.landing-nav {
  position: fixed;
  top: 0;
  height: 70px;
  padding: 1em 1.5em 1em 1.5em;
  box-shadow: 0 1px 5px 0px hsla(0, 0%, 0%, 0.25);
  z-index: 10;
  width: 100%;
  background-color: white;
}

.landing-content {
  overflow-x: auto;
}

.feature-container {
  display: flex;
  align-items: center;
  margin-bottom: 250px;
  min-height: 75%;
}
.feature-container.reverse {
  flex-direction: row-reverse;
}
.feature-container:last-of-type {
  margin-bottom: 25px;
}

.feature-text {
  width: 50%;
  padding: 3em;
  text-align: right;
}
.feature-text p {
  font-size: 18px;
}

.feature-image {
  width: 50%;
  padding: 3em;
}

.feature-image.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder-image {
  width: 100%;
  height: 300px;
  background-color: #00d1b2;
}

@media screen and (max-width: 768px) {
  .landing-page {
    width: 100vw;
    height: 100vh;
    grid-template-rows: 165px auto;
  }

  .landing-nav {
    padding: 0;
  }

  .landing-content {
    width: 100%;
    height: calc(100vh - 165px);
    overflow-x: auto;
  }

  .feature-container {
    flex-direction: column-reverse;
  }

  .feature-container.reverse {
    flex-direction: column-reverse;
  }

  .feature-text {
    width: 95%;
  }

  .feature-image {
    width: 95%;
  }
}

.data-paragraph {
  min-height: 100vh;
  width: 100%;
  min-width: 768px;
  table-layout: fixed;
}
.data-paragraph thead {
  border-bottom: #dddbe0 1px solid;
}
.data-paragraph th,
.data-paragraph td {
  padding: 16px;
  overflow: auto;
}

.col-1 {
  width: 20%;
}
.col-2 {
  width: 15%;
}
.col-3 {
  width: 40%;
}
.col-4 {
  width: 25%;
}

.data-paragraph-button {
  width: 100vw;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
