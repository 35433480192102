/* Main styles */
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.mobile-header {
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0 1px 5px 0px hsla(0, 0%, 0%, 0.25);
  flex: 0 0 80px;
}
.header-text {
  align-items: flex-start;
}

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 15px;
  overflow: auto;
  flex: 1 1;
}

/* Domain Card Styles */
.domain-card {
  color: white;
  height: 22%;
  border-radius: 10px;
  padding: 2em;
  box-shadow: 0 2px 6px 2px #00000017;
}
.domain-card-header {
  color: white;
  font-weight: 400;
}
.domain-card-subheader {
  color: white;
  font-weight: 400;
}

/* Concept List & Card Styles */
.concept-list {
  height: 100vh;
}

.concept-list-header,
.concept-header {
  justify-content: flex-start;
  flex: 0 0 80px;
  width: 100%;
  padding: 1em;
  box-shadow: 0 1px 5px 0px hsla(0, 0%, 0%, 0.25);
}
.concept-list-header svg,
.concept-header svg {
  margin-right: 15px;
}
.concept-list-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow: auto;
  flex: 1 1;
  width: 100%;
}

.concept-card {
  position: relative;
  border-radius: 10px;
  margin: 10px 0;
  box-shadow: 0 2px 6px 2px #00000017;
}
.concept-card-icon {
  height: 65px;
  width: 65px;
  background-color: grey;
  margin: 1em;
}
.concept-card-finished-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.concept-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 16px;
  color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* border-top: 1px solid rgba(0, 0, 0, 0.15); */
}

/* Concept Page Syles */
.concept {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.concept-container {
  flex: 1 1;
  overflow: auto;
}
.concept-finished {
  padding: 1em;
}
.concept-finished-icon {
  position: absolute;
  right: 0;
}

.challenge-list-mobile {
  padding: 1em;
}

.challenge {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

/* Report Page Styles */
.mobile-report {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.report-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
  padding: 10px;
  overflow: auto;
}

.mobile-report-list {
  width: 100%;
}
.mobile-report-sort {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  color: white;
  background-color: #0ca7d3;
  border-radius: 10px;
  padding: 10px;
}

.report-card {
  border-radius: 10px;
  box-shadow: 3px 3px 15px #e5e5e5;
  margin: 25px 0;
  padding: 15px;
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.card-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100px;
  margin: 10px 0;
  border-radius: 50px;
  color: white;
}

.card-btns {
  display: flex;
  margin-top: 25px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -15px;
}

.card-btn {
  width: 50%;
  text-align: center;
  padding: 15px;
  border-top: 1px solid #e5e5e5;
  border-collapse: collapse;
}

.b-left {
  border-left: 1px solid #e5e5e5;
}

.b-right {
  border-right: 1px solid #e5e5e5;
}

.needs-modal,
.challenges-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 50px;
  margin: 50px;
  min-height: 250px;
  background-color: white;
}

.challenges-modal {
  text-align: left;
}
.challenges-modal div {
  margin: 5px 0;
}
