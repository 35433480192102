.home-desktop {
  display: grid;
  grid-template-columns: 250px auto;
  grid-template-rows: 70px auto;
  grid-template-areas:
    'header header'
    'menu concept';
  height: 100vh;
}

.nav-desktop {
  padding: 1em 1.5em 1em 1.5em;
  box-shadow: 0 1px 5px 0px hsla(0, 0%, 0%, 0.25);
  grid-area: header;
  z-index: 10;
}

.menu-desktop {
  grid-area: menu;
  padding: 10px 25px;
  box-shadow: 1px 0 5px 0px #dbdbdb;
  overflow: auto;
  position: relative;
}

.menu-footer {
  font-size: 0.8em;
  width: 100%;
  margin-top: 15px;
  padding-top: 5px;
  border-top: 1px solid #dbdbdb;
}

.concept-desktop {
  grid-area: concept;
  overflow: auto;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.horizontal {
  justify-content: space-between;
  flex-direction: row;
}

.menu-list {
  font-size: 1.2rem;
}

.menu-list a.is-finished {
  background-color: #00b89cad;
  color: white;
}

.menu-list a.is-active {
  background-color: #0ca7d3;
}

.concept-header-desktop {
  background-color: #0ca7d3;
}

.concept-header-desktop .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.concept-challenges {
  padding: 1em 1.5em;
}

.concept-icon {
  background-color: white;
  width: 85px;
  height: 85px;
  border-radius: 10px;
  padding: 5px;
}

.hero-body .title {
  color: white;
  margin: 0;
}

.hero-body {
  padding: 0.75em 2.5em;
}

.challenge-list-desktop.large-font {
  font-size: 24px;
}

.challenge-list-desktop .challenge {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
}

.concept-btn-container {
  margin: 10px 0;
}

.finished-modal {
  background-color: white;
  border-radius: 10px;
  padding: 50px;
}

/* ================== */
/* Report Page Styles */
/* ================== */

.report-desktop {
  padding-top: 70px;
  height: 100vh;
}

.report-nav-desktop {
  position: fixed;
  width: 100%;
  top: 0;
  height: 70px;
  padding: 1em 1.5em 1em 1.5em;
  box-shadow: 0 1px 5px 0px hsla(0, 0%, 0%, 0.25);
  z-index: 10;
  background-color: white;
}

@media print {
  .report-nav-desktop {
    display: none !important;
  }
  button {
    display: none !important;
  }
}

.report {
  overflow: auto;
  padding-bottom: 25px;
  min-height: 100%;
}

.report-table {
  width: 100%;
}

.report-table td,
.report-table th {
  text-align: center;
}

.report-table th {
  padding: 5px;
}

.th-center {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.report-row {
  border-top: 1px solid #dddbe0;
}

.report-row td {
  padding: 10px;
}

.report-needs {
  color: white;
  border-radius: 25px;
  height: 25px;
  background-color: grey;
  margin: 5px 0;
  white-space: nowrap;
}

.report-star {
  cursor: pointer;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.report-print-btn {
  color: white;
  outline: none;
  border: none;
  background-color: #0ca7d3;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  border-radius: 10px;
}
.report-print-btn:hover {
  color: white;
  background-color: #0ca7d3;
  transform: scale(1.1);
}
.report-print-btn:active {
  transform: scale(1);
}

.submit-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 50px;
}
